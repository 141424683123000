import { Markdown } from '@payfit/midnight/dist/Markdown';
import React, { FC } from 'react';

import {
  textBody,
  textTitle1,
} from '../../../../growth-platform-brand-system-v2/src/styles/typography';
import { classNames } from '../../../../growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from '../../../../growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { Table } from '../../components/Table';
import '../../sass/styles.scss';

export interface LegalPageArticleProps {
  blocks?: any[];
  medias?: any[];
  title?: string;
  lastUpdate?: string;
  locale?: 'fr' | 'en' | 'es';
  className?: string;
}

const getMarkdown = (md: any) => <Markdown>{md}</Markdown>;
const versionTranslate: any = {
  fr: 'Accédez aux versions précédentes en cliquant ici :',
  en: 'Access the previous versions by clicking here:',
  es: 'Acceda a las versiones anteriores haciendo clic aquí:',
};

export const LegalPageArticle: FC<LegalPageArticleProps> = ({
  blocks,
  medias,
  title,
  lastUpdate,
  locale,
  className,
}: any) => {
  return (
    <section
      className={classNames(
        'LegalPageArticle',
        className,
        'container flex justify-center flex-col lg:w-8/12',
      )}
    >
      {title && <h1 className={textTitle1}>{title}</h1>}
      {lastUpdate && (
        <p className={classNames(textBody, 'text-neutral-101 italic mb-20')}>
          {lastUpdate}
        </p>
      )}
      {medias?.length > 0 && (
        <div>
          <p className={classNames(textBody, 'text-neutral-101 my-20')}>
            {versionTranslate[locale]}
          </p>
          {medias.map((media: any, i: number) => (
            <div key={i}>
              <ButtonTracking
                component={'a'}
                section="legalText"
                index={i}
                href={media?.url}
                className={classNames(
                  textBody,
                  'text-blue-101 underline hover:no-underline py-8',
                )}
                labelTracking={media?.title}
              >
                {media?.title ? media?.title : media?.url}
              </ButtonTracking>
            </div>
          ))}
        </div>
      )}
      {blocks?.reduce((acc: any[], block: any, i: number) => {
        if (block.internal.type === 'DatoCmsTable') {
          acc.push(<Table key={i} markdown={block.markdown} />);
        }
        if (block.internal.type === 'DatoCmsMarkdown') {
          acc.push(
            <div key={i} className="my-20 text-justify">
              {getMarkdown(block.markdown)}
            </div>,
          );
        }
        return acc;
      }, [])}
    </section>
  );
};
